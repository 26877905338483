import React from "react"
import Layout from "../components/layout"
import BackLink from "../components/backlink"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import mapboxgl from "mapbox-gl"

class SubPage extends React.Component {
  createMap(center, zoom) {
    const { data } = this.props
    const mapPoints = data.contentfulPosts.mapPoints

    var lonlat = center.split(",")

    var lon = lonlat[0]
    var lat = lonlat[1]

    const coordinates = [lon, lat]

    var places = mapPoints.map(node => ({
      type: "Feature",
      title: node.title,
      properties: {
        title: node.title,
        description: node.title,
        slug: node.slug,
      },
      geometry: {
        type: "Point",
        coordinates: [node.geocode.lon, node.geocode.lat],
      },
    }))

    //const coordinates = [-2.884845,54.037619]
    const map = new mapboxgl.Map({
      center: coordinates,
      container: "map",
      style: "mapbox://styles/mapbox/satellite-v9",
      zoom: zoom,
    })

    map.on("load", function () {
      map.addLayer({
        id: "Places",
        type: "circle",
        source: {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: places,
          },
        },

        paint: {
          "circle-radius": 7,
          "circle-color": "#007cbf",
          "circle-opacity": 0.8,
          "circle-stroke-width": 0,
        },
      })
    })
    map.addControl(new mapboxgl.NavigationControl())

    map.on("click", "Places", function (e) {
      var coordinates = e.features[0].geometry.coordinates.slice()
      var description = "<h3>" + e.features[0].properties.description + "</h3>"
      var locationLink =
        "<a href=/location/" +
        e.features[0].properties.slug +
        ">click for info</a>"

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
      }

      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(description + locationLink)
        .addTo(map)
      var clickedPoint = e.features[0]
      flyToStore(clickedPoint)
    })

    function flyToStore(currentFeature) {
      map.flyTo({
        center: currentFeature.geometry.coordinates,
      })
    }

    map.on("mouseenter", "Places", function () {
      map.getCanvas().style.cursor = "pointer"
    })

    map.on("mouseleave", "Places", function () {
      map.getCanvas().style.cursor = ""
    })
  }

  componentDidMount() {
    const page = this.props.data.contentfulPosts

    if (page.body.childMarkdownRemark.html.indexOf("[map") >= 1) {
      console.log("lets show a map")

      var startpoint = page.body.childMarkdownRemark.html.indexOf("[map") - 28
      var endpoint = page.body.childMarkdownRemark.html.indexOf("]</code>") + 8

      var complete = page.body.childMarkdownRemark.html.substring(
        startpoint,
        endpoint
      )

      //strip shortcode leaving center and zoom
      complete = complete.replace('<code class="language-text">[map ', "")
      complete = complete.replace("]</code>", "")
      complete = complete.split(" ")
      var center = complete[0].replace("center=&quot;", "")
      var zoom = complete[1].replace("zoom=&quot;", "")
      center = center.replace("&quot;", "")
      zoom = zoom.replace("&quot;", "")

      mapboxgl.accessToken =
        "pk.eyJ1Ijoic2VyaXRoIiwiYSI6ImNqcGI1eXN4dDBtcWwzcXAyNGsycG0xaWgifQ.Qg3PAYHQ72Qem7VbWCb0ZQ"
      this.createMap(center, zoom)
    }
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteTagline = data.site.siteMetadata.description
    const page = this.props.data.contentfulPosts

    //check for map shortcode
    if (page.body.childMarkdownRemark.html.indexOf("[map") >= 1) {
      var startpoint = page.body.childMarkdownRemark.html.indexOf("[map") - 28
      var endpoint = page.body.childMarkdownRemark.html.indexOf("]</code>") + 8
      var complete = page.body.childMarkdownRemark.html.substring(
        startpoint,
        endpoint
      )
      var bodycontent = page.body.childMarkdownRemark.html.replace(
        complete,
        "<div id='map' class='map'></div>"
      )
    } else {
      bodycontent = page.body.childMarkdownRemark.html
    }

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        tagline={siteTagline}
      >
        <SEO
          title={page.title}
          keywords={["blog", "gatsby", "javascript", "react"]}
        />
        <div className="post">
          <h1>{page.title}</h1>

          <div
            dangerouslySetInnerHTML={{
              __html: bodycontent,
            }}
          />

          <BackLink origin="article" />
        </div>
      </Layout>
    )
  }
}

export default SubPage

export const articleQuery = graphql`
  query articleQuery($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    contentfulPosts(slug: { eq: $slug }) {
      id
      title
      body {
        body
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
      mapPoints {
        id
        title
        slug
        geocode {
          lon
          lat
        }
      }
    }
  }
`
